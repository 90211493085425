import * as SageConstants from "../config/SageConstants";

export function getDateFromToday(offsetFromToday) {
    const todaysDate = new Date();
    const reqdTime = new Date(
        todaysDate.getTime() - offsetFromToday * 24 * 60 * 60 * 1000
    );

    const currentOffset = reqdTime.getTimezoneOffset();
    const ISTOffset = 330; // IST offset UTC +5:30
    const today = new Date(
        reqdTime.getTime() + (ISTOffset + currentOffset) * 60000
    );

    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    if (mm < 10) mm = "0" + mm;
    const yyyy = today.getFullYear();

    if (dd < 10) dd = "0" + dd;
    return yyyy + "-" + mm + "-" + dd;
}

export function getTodayDate() {
    let months = [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ]
    const currentTime = new Date();
    const currentOffset = currentTime.getTimezoneOffset();
    const ISTOffset = 330; // IST offset UTC +5:30
    const today = new Date(
        currentTime.getTime() + (ISTOffset + currentOffset) * 60000
    );

    let dd = today.getDate();
    const mm = months[today.getMonth()];
    const yyyy = today.getFullYear();

    if (dd < 10) dd = "0" + dd;
    return dd + " " + mm + " " + yyyy;
}

export function getTaxRate(countryCode) {
    if (
        countryCode &&
        Object.prototype.hasOwnProperty.call(
            SageConstants.taxRates,
            countryCode
        )
    ) {
        return SageConstants.taxRates[countryCode];
    }
    return SageConstants.taxRates["IN"];
}

export function getCurrency(countryCode) {
    if (
        countryCode &&
        Object.prototype.hasOwnProperty.call(
            SageConstants.currencyCountry,
            countryCode
        )
    ) {
        return SageConstants.currencyCountry[countryCode];
    }
    return "";
}

export function getAmountInclusiveTaxes(credits, countryCode, exchangeRate) {
    let priceInclusiveOfTaxes =
        (credits / exchangeRate * (100 + getTaxRate(countryCode))) /
        100;
    return Math.round((priceInclusiveOfTaxes + Number.EPSILON) * 100) / 100;
}

export function getAmountExclusiveTaxes(credits, exchangeRate) {
    let priceExclusiveOfTaxes = credits / exchangeRate;
    return Math.ceil(Math.round((priceExclusiveOfTaxes + Number.EPSILON) * 100) / 100);
}

export function getMFCreditsFromAmount(amount, countryCode, exchangeRate) {
    let credits = (amount * exchangeRate * 100) / (100 + getTaxRate(countryCode));
    return Math.round((credits + Number.EPSILON) * 100) / 100;
}

export function sendMsgToWhatsapp(whatsappNo, message) {
    let waURL = `https://wa.me/${encodeURIComponent(whatsappNo)}/?text=${encodeURIComponent(message)}`;
    window.open(waURL, "_blank");
}

export function showWarningBorder(id) {
    let elements = document.getElementsByClassName(id);
    for (let el of elements) {
        el.classList.remove("border-md");
        el.classList.add("border-danger");
    }
}

export function showNormalBorder(id) {
    let elements = document.getElementsByClassName(id);
    for (let el of elements) {
        el.classList.add("border-md");
        el.classList.remove("border-danger");
    }
}
export function getSuccessTextFromRedeemResponse(response, pid) {
    // playerName: playerInfo.playerName,
    //                 gameName: retGame.name,
    //                 packageName: pkg.name,
    //                 transactionId: grantPackageData.transactionId,
    //                 voucherCode: giftCardRedeemInfo.giftCardInfo.voucherCode,
    //                 voucherPartner: VMS_OPTIONS.MINTROUTE,
    //                 gameBalance: grantPackageData.userBalance
    const content = document.createElement("p");
    content.setAttribute("id", "swal-content");
    content.style.cssText = "word-break:break-word";
    content.innerHTML = ""
    if(response.reason){
        content.innerHTML += "Reason : " + response.reason + "<br>" 
    }
    content.innerHTML += `<hr>`
    content.innerHTML +=
        "Please save this info for future reference <br><br><b><u>Recharge Details:</u><b>";
        content.innerHTML += `<br>Transaction ID: <b> ${response.transactionId} </b>`;
    if (response.playerName) {
        content.innerHTML += `<br>Player Name: <b> ${response.playerName} </b>`;
    }
    content.innerHTML += `<br>Player ID: <b> ${pid} </b>`;
    content.innerHTML += `<br>Game Name: <b> ${response.gameName} </b>`;
    content.innerHTML += `<br>Package Name: <b> ${response.packageName} </b>`;
    // content.innerHTML += `<br>Updated balance: <b> ${response.gameBalance} </b>`;
    
    content.innerHTML += `<br><br><b><u>Voucher Details:</u><b>`;
    content.innerHTML += `<br>Voucher Source: <b> ${response.voucherPartner} </b>`;
    content.innerHTML += `<br>${getVoucherCodeText(response.voucherPartner)}: <b> ${response.voucherCode} </b>`;
    if(response.transactionAmount)
    content.innerHTML += `<br>Voucher Code: <b> ${response.transactionAmount.amount} </b> <b> ${response.transactionAmount.currency} </b>`;
    if(response.remainingAmount)
    content.innerHTML += `<br>Voucher Code: <b> ${response.remainingAmount.amount} </b> <b> ${response.remainingAmount.currency} </b>`;
    
    if(response.voucherSerial){
    content.innerHTML += `<br>Voucher Serial Number: <b> ${response.voucherSerial} </b>`;
    }
    content.innerHTML += `<hr>`
    if(response.errorMsg){
    content.innerHTML += `<b><i> ${response.errorMsg} </i></b>`;
    }
    content.innerHTML += `For any queries please contact <i>customer.service@moonfroglabs.com</i>`
    return content;
}
export function getVoucherCodeText(voucherPartner){
    switch(voucherPartner){
      case SageConstants.MINTROUTE:
      case SageConstants.STELLR:
        return 'Voucher Code'
      case SageConstants.QWIKCILVER:
        return 'Gift Card No'
      case SageConstants.EZPIN:
        return 'Voucher Pin'
    }
  }
export function printMessage() {
    let divContents = document.getElementById("swal-content").innerHTML;
    let printWindow = window.open("", "", "height=400,width=800");
    printWindow.document.write(
        "<html><head><title>Redemption Information</title>"
    );
    printWindow.document.write("</head><body>");
    printWindow.document.write("<h1>Redemption Information</h1>");
    printWindow.document.write(divContents);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
}

export function getSuccessTextFromPaymentResponse(response) {
    // RedeemAPIResponse : {
   
    // transactionId: string,
    // voucherCode: string,
    // voucherPartner: string,
    // transactionAmount?: number,
    // }

    // paymentStatus: PAYMENT_STATUS,
    // redeemResponse?: RedeemAPIResponse,
    // playerName?: string,
    // targetPid: string,
    // gameName: string,
    // transactionId: string,
    // packageName: string,
    // gameBalance?: number,
    // errorMsg?: string,
    // reason ?: string,
    const content = document.createElement("p");
    content.setAttribute("id", "swal-content");
    content.style.cssText = "word-break:break-word";
    content.innerHTML = ""
    if(response.reason){
    content.innerHTML += "Reason : " + response.reason + "<br>"
    }
    content.innerHTML += "<hr>"
    content.innerHTML +=
        "Please save this info for future reference <br><br><b><u>Recharge Details:</u><b>";
        content.innerHTML += `<br>Transaction ID: <b> ${response.transactionId} </b>`;
        content.innerHTML += `<br>Transaction Amount : <b> ${response.transactionAmount.amount} ${response.transactionAmount.currency} </b>`;
    
    if (response.playerName) {
        content.innerHTML += `<br>Player Name: <b> ${response.playerName} </b>`;
    }
    content.innerHTML += `<br>Player ID: <b> ${response.targetPid} </b>`;
    content.innerHTML += `<br>Game Name: <b> ${response.gameName} </b>`;
    content.innerHTML += `<br>Package Name: <b> ${response.packageName} </b>`;
    // content.innerHTML += `<br>Updated balance: <b> ${response.gameBalance} </b>`;
    if(response.redeemResponse){
    content.innerHTML += `<br><br><b><u>Voucher Txn details :</u><b>`;
    content.innerHTML += `<br>Txn Id: <b> ${response.redeemResponse.transactionId} </b>`;
    content.innerHTML += `<br>Voucher Source: <b> ${response.redeemResponse.voucherPartner} </b>`;
    content.innerHTML += `<br>Voucher Code: <b> ${response.redeemResponse.voucherCode} </b>`;
    content.innerHTML += `<br>Transaction Amount : <b> ${response.redeemResponse.transactionAmount.amount} ${response.redeemResponse.transactionAmount.currency} </b>`;
    }
    if(response.errorMsg){
    content.innerHTML += `<br><b><i> ${response.errorMsg} </i></b>`;
    }
    content.innerHTML += `<hr>`
    content.innerHTML += `For any queries please contact <i>customer.service@moonfroglabs.com</i>`
    return content;
}
export const PAYMENT_STATUS ={
    SUCCESS : "SUCCESS",
    PENDING : "PENDING",
    FAILED : "FAILED",
    REFUNDED : "REFUNDED",
    IN_PROGRESS  : "IN_PROGRESS"
}
export const GIFT_CARD_TRANSACTION_STATUS = {
    PENDING : "PENDING",
    REDEEMED : "REDEEMED",
    REVERSED : "REVERSED",
    FAILED : "FAILED"
}
export function getIconFromResponse(status) {
    switch(status){
        case PAYMENT_STATUS.SUCCESS:
        case GIFT_CARD_TRANSACTION_STATUS.REDEEMED :
            return "success"
        case PAYMENT_STATUS.REFUNDED:
        case GIFT_CARD_TRANSACTION_STATUS.REVERSED:
            return "info"
        case PAYMENT_STATUS.FAILED:
        case GIFT_CARD_TRANSACTION_STATUS.FAILED:
            return "error"
        case PAYMENT_STATUS.PENDING:
        case GIFT_CARD_TRANSACTION_STATUS.PENDING:
            return "warning"
        case PAYMENT_STATUS.IN_PROGRESS:
            return "info"
       
        default:
            console.error("Payment status not available" )

    }
}
export function getTitleFromResponse(status) {
    switch(status){
        case PAYMENT_STATUS.SUCCESS :
        case GIFT_CARD_TRANSACTION_STATUS.REDEEMED :
            return "Payment is Successful"
        case PAYMENT_STATUS.REFUNDED:
        case GIFT_CARD_TRANSACTION_STATUS.REVERSED:
        
            return "Payment is Refunded"
        case PAYMENT_STATUS.FAILED:
        case GIFT_CARD_TRANSACTION_STATUS.FAILED:
            return "Payment Failed"
        case PAYMENT_STATUS.PENDING:
        case GIFT_CARD_TRANSACTION_STATUS.PENDING:
            return "Payment not confirmed yet"
        case PAYMENT_STATUS.IN_PROGRESS:
            return "Granting Package"
        default:
            console.error("Payment status not available" )
    }
}